import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'

export default{
    data : ()=>({
        chosenDiningOption: null,
        chosenServeNow: true,
        chosenDiningOptionObject: {
            name: "",
            description: ""
        },
        pickupSettings: "now",
        delivery_address:{
            country:'',
            state:'',
            first_name:'',
            last_name:'',
            city:'',
            postal_code:'',
            phone:'',
            address_1:'',
            address_2:''
        },
        initial: null,
        delivery_date_min: null,
        delivery_date_max: null,
        delivery_date: null,
        delivery_time: null,
        deliveryTimeLimitExceed: false,
        timeLimit:null,
        enableAddressTextField:false,
        enableAddressTextFieldLabel:'Add new address',
        isAddressMissing:false,
        addressSpaceRegex:/^ +$/,
        addressEmptyRegex:/^$/
    }),
    computed:{
        ...mapGetters({
            searchedPlaces:'order/searchedPlaces',
			userId : 'auth/activeUserId',
			addresses : 'address/addresses',
			addressOptions: 'address/addressOptions',
            countryOptions:'address/countries',
            statesOptions:'address/states',
            getDeliveryAddress:'getDeliveryAddress',
			storeCountry:'storeCountry'
        }),
        availableDiningOptions () {
			return this.$store.getters.availableDiningOptions;
		},
		serveAtNew () {
			return new Date(`${this.delivery_date}T${this.delivery_time}`).toISOString();
		},
        selectedDeliveryAddress () {
			return this.$store.getters.getDeliveryAddress;
		}
    },
    watch:{
        getDeliveryAddress:{
            handler(val){
                this.delivery_address = val
            }
        }
    },
    async created() {
        this.chosenDiningOption = this.$store.getters.dining_option;
        this.chosenDiningOptionObject = this.availableDiningOptions.find(d => d.id === this.chosenDiningOption);
		await this.fetchCountriesAndStates()
		const initialDate = new Date();
		const minDatetime = new Date(initialDate);
		const maxDatetime = new Date(initialDate);
		this.delivery_date_min = new Date(minDatetime.setMinutes(minDatetime.getMinutes() + 30));
		this.delivery_date_max = new Date(maxDatetime.setDate(maxDatetime.getDate() + 7));
		this.delivery_date = initialDate.toISOString().split("T")[0];
		const delivery_time = new Date(initialDate);
		delivery_time.setMinutes(delivery_time.getMinutes() + 30);
		this.delivery_time = `${("0" + delivery_time.getHours()).substr(-2)}:${("0" + delivery_time.getMinutes()).substr(-2)}:${("0" + delivery_time.getSeconds()).substr(-2)}`;
		setInterval(() => {
			// if the order to be placed is for delivery and is to be scheduled later
			if (this.chosenDiningOptionObject.is_deliverable && this.pickupSettings === "later") {
			// get current time + 30 minutes
				const timeLimit = moment().add({
					'minutes': 30
				}).format("HH:mm")
				// convert 24hrs to 12 hrs format to show on screen
				this.timeLimit = moment(timeLimit, 'HH:mm:ss').format("h:mm A")
				// convert removing seconds from the delivery_time
				const deliveryTime = moment(this.delivery_time, "HH:mm:ss").format("HH:mm")

				if (deliveryTime < timeLimit) {
					this.deliveryTimeLimitExceed = true
				}
				else {
					this.deliveryTimeLimitExceed = false
				}
			}
			else { 
				// update the delivery time timepicker after every half second
				this.delivery_time = moment().add({
					'minutes': 30
				}).format("HH:mm")
			}
		}, 500)
	},
    mounted(){
        this.delivery_address = {...this.getDeliveryAddress}
    },
    methods:{
        ...mapActions({
            searchPlace:'order/searchPlace',
            fetchCustomerAddresses:'address/fetchCustomerAddresses',
            fetchCountriesAndStates:'address/fetchCountriesAndStates'
        }),
        setNewDiningOption (newDiningOptionId) {
			this.chosenDiningOption = newDiningOptionId;
			this.chosenDiningOptionObject = this.availableDiningOptions.find(d => d.id === newDiningOptionId);
			if (!this.chosenDiningOptionObject.is_scheduling_enabled) {
				this.pickupSettings = "now";
			}
		},
        toggleAddressSelection(){
			this.enableAddressTextField = !this.enableAddressTextField
			if(this.enableAddressTextField){
				this.enableAddressTextFieldLabel = 'Choose from exsisting address'
				this.delivery_address = ''
			}
			else{
				this.enableAddressTextFieldLabel = 'Add new address'
				this.delivery_address = ''
			}

		},
        onShow () {
			this.chosenDiningOption = this.$store.getters.dining_option;
			this.chosenDiningOptionObject = this.availableDiningOptions.find(d => d.id === this.chosenDiningOption);
			this.pickupSettings = !!this.$store.getters.getServeAt;
			this.pickupSettings = this.serveAt ? "later" : "now";
		},
		isScheduleEnabled () {
			const scheduleObj = this.availableDiningOptions.find(d => d.id === this.chosenDiningOption);
			return scheduleObj && scheduleObj.is_scheduling_enabled;
		},
		saveDinnerOption (event) {
			if((this.chosenDiningOptionObject && this.chosenDiningOptionObject.is_deliverable ) && (!this.delivery_address.address_1 || this.addressSpaceRegex.test(this.delivery_address.address_1) || this.addressEmptyRegex.test(this.delivery_address.address_1)))
            {
                this.isAddressMissing = true
				event.preventDefault();
				return
            }
			this.$store.commit("setDiningOption", this.chosenDiningOption);
			this.$store.commit("setServeAt", this.pickupSettings === "now" ? "" : this.serveAtNew);
			this.delivery_address['local_id'] = (new Date).toISOString()
			this.delivery_address['country'] = this.storeCountry
			this.$store.commit("setDeliveryAddress", this.delivery_address);
			this.isAddressMissing = false
		}
    }
    
}
import { login } from "../../helpers/apiHelper";

export const auth = {
         namespaced: true,
         state: {
            isValidUser : false,
            loggedUser : {},
            openLoginModal:false,
            loginLoader:false
         },
         getters: {
            isValidUser : (state) => state.isValidUser,
            activeUserId : state => state.loggedUser?.id,
            openLoginModal : state => state.openLoginModal,
            loginLoader : state => state.loginLoader,
            loggedUser: state => state.loggedUser
         },
         mutations: {
            SET_VALID_USER:(state,isValid) => state.isValidUser = isValid,
            SET_ACTIVE_USER : (state,activeUser) => state.loggedUser = activeUser,
            SET_OPEN_LOGIN_MODAL : (state,openModal) => state.openLoginModal = openModal,
            SET_LOADER : (state,loader) => state.loginLoader = loader
            
         },
         actions: {
            async authenticateUser({commit},payload){
               try{
                  commit('SET_LOADER',true)
                  const response  =  await login(payload)
                  if(response.status === 200){
                     commit('SET_VALID_USER',true);
                     commit('SET_ACTIVE_USER',response.data)
                     commit('SET_OPEN_LOGIN_MODAL',false)
                     commit('SET_LOADER',false)
                  }
               }
               catch(err){
                  commit('SET_OPEN_LOGIN_MODAL',true)
                  commit('SET_VALID_USER',false)
                  commit('SET_LOADER',false)
               }
            },
            toggleLoginModal({commit},toggle){
               commit('SET_OPEN_LOGIN_MODAL',toggle)
            }

         }
       };
